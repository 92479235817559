import { styled } from "styled-components";
import { Button } from "./Button";
import { useModal } from "../hooks/useModal";
import { useAuth } from "../hooks/useAuth";
import { Show } from "./Show";
import { useViewport } from "../hooks/useViewport";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 6rem;
  padding-right: 2rem;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export type MenuItem = "how-it-works" | "pricing";

export type TopNavProps = {
  onMenuClick?: (item: MenuItem) => void;
};

export const TopNav = (props: TopNavProps) => {
  const { onMenuClick } = props;
  const { accessToken, signOut } = useAuth();
  const { openModal } = useModal();
  const { isDesktop } = useViewport();

  return (
    <Container>
      <ActionContainer>
        <Show
          when={!accessToken}
          whenFalseContent={
            <Button onClick={signOut} buttonType="secondary" label="Sign Out" />
          }
        >
          <Show when={!!onMenuClick && isDesktop}>
            <Button
              onClick={() =>
                onMenuClick ? onMenuClick("how-it-works") : undefined
              }
              buttonType="menu"
              label="How It Works"
            />
            <Button
              onClick={() => (onMenuClick ? onMenuClick("pricing") : undefined)}
              buttonType="menu"
              label="Pricing"
            />
          </Show>
          {/* <Button
            onClick={() => openModal("create-individual")}
            buttonType="primary"
            label="Individual"
          /> */}
          <Button
            onClick={() => openModal("create-organization")}
            buttonType="primary"
            label="Organization"
          />
          <Button
            onClick={() => openModal("sign-in")}
            buttonType="secondary"
            label="Sign In"
          />
        </Show>
      </ActionContainer>
    </Container>
  );
};
